// Restaurant.js
import React from 'react';
import '../category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant = () => {

  const imageUrl = 'https://lh5.googleusercontent.com/p/AF1QipOEgz_jBGS6tkDfhozHUgXbxu4fR32vyIbO-0VS=w408-h306-k-no';

  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Restaurants</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Restaurants"/>
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Restaurant;