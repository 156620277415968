// Concerts004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPZx3PyMbIMx9u9EdxOPvRZyLKfEh_4RAcFvnJT=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">O'Neill's Wardour Street</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="O'Neill's Wardour Street"/>
      </div>
    </div>
  );
};

export default Concerts004;
