// Museum001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Museum001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNLCMPDpM_sq4EAloceZiOAFSqdJjQu1Uv4oBZw=w408-h272-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Duomo di Milano</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Duomo di Milano"/>
      </div>
    </div>
  );
};

export default Museum001;
