// Museum.js
import React from 'react';
import '../category.css';
import './Alt.css'; // Import your additional CSS

const Museum = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNvmu5p3m2kCiiiw5onxHXpMYS_ZxZouEFHzRT3=w80-h106-k-no';

  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Museums</div> {/* Explicitly specify the title here */}  
        <img src={imageUrl} alt="Museums" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Museum;