// Museum009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipP9fuBQCNFgSCdIaGeTvQZohSwkagyWtrGCp1rn=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">SAMURAI NINJA MUSEUM TOKYO</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="SAMURAI NINJA MUSEUM TOKYO"/>
      </div>
    </div>
  );
};

export default Museum009;
