// Bar009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNB_GRtPAls8iER2qo8yww9J5rC0R1Djpnf9l7T=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Kilburn Cocktail Bar Milano</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Kilburn Cocktail Bar Milano"/>
      </div>
    </div>
  );
};

export default Bar009;
