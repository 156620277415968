import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Coffee1 from './001_Coffee.jsx';
import Coffee2 from './002_Coffee.jsx';
import Coffee3 from './003_Coffee.jsx';

import Coffee4 from './004_Coffee.jsx';
import Coffee5 from './005_Coffee.jsx';
import Coffee6 from './006_Coffee.jsx';

import Coffee7 from './007_Coffee.jsx';
import Coffee8 from './008_Coffee.jsx';
import Coffee9 from './009_Coffee.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Coffee1 className="component" />
        <Coffee2 className="component" />
        <Coffee3 className="component" />
      </div>

      <div className="row">
        <Coffee4 className="component" />
        <Coffee5 className="component" />
        <Coffee6 className="component" />
      </div>

      <div className="row">
        <Coffee7 className="component" />
        <Coffee8 className="component" />
        <Coffee9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
