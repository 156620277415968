// Restaurant004.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant004 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOQ6_5NJeExJ3aKQMI4fsn58D-MpBXYmidFr80t=w408-h240-k-no-pi0-ya272.22955-ro-0-fo100';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Barbacoa</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Barbacoa"/>
      </div>
    </div>
  );
};

export default Restaurant004;
