// Museum004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOaNTIjP158JUer9p8OT1NT40Vt4fLDngoQ_cYc=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Museo Soumaya</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Museo Soumaya"/>
      </div>
    </div>
  );
};

export default Museum004;
