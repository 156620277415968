// NightClub005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNyQeTSuRhZyMU8D1Gq7oRS_c7_9SNVcqn6z76K=w426-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Catch One</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Catch One"/>
      </div>
    </div>
  );
};

export default NightClub005;
