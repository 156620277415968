// Restaurant002.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant002 = () => {

  const imageUrl = 'https://lh5.googleusercontent.com/p/AF1QipPN0f-lyovNxCTa10Y5yyQdBcShNitWmsEcRip6=w426-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">La Casa de Toño</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="La Casa de Toño"/>
      </div>
    </div>
  );
};

export default Restaurant002;
