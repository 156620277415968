// Bar001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Bar001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipP6NpGE23ia-3jXcQWnzJi06-NF3QHYbGZgofB1=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Freud Bar & Gallery</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Freud Bar & Gallery"/>
      </div>
    </div>
  );
};

export default Bar001;
