// Bookstore008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOqWNhphaq3tv1Lo24MLjOsUgdiBBJ-rbtcEbnE=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Aoyama</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Aoyama"/>
      </div>
    </div>
  );
};

export default Bookstore008;
