// Museum007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNBOsXOwst3a7_0cxDXfrIc6Tap_EM3uJPHahbV=w408-h271-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">GRAMMY Museum L.A. Live</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="GRAMMY Museum L.A. Live"/>
      </div>
    </div>
  );
};

export default Museum007;
