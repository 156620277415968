// Bar005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOFVv9O3zms62BO9cKnKNpbkPowmvnArPrzsTI=w426-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Spire 73</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Spire 73"/>
      </div>
    </div>
  );
};

export default Bar005;
