// NightClub008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOt7Ph6fiiOGu20oGJ65O6PsezScGYCpwR4KiUX=w153-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Heaven</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Heaven"/>
      </div>
    </div>
  );
};

export default NightClub008;
