// Restaurant006.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant006 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMqQM4qmeqNVzTjGaU6PdK05d2_Pn16BCHClP_M=w408-h240-k-no-pi0-ya256-ro-0-fo100';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Olive Garden</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Olive Garden"/>
      </div>
    </div>
  );
};

export default Restaurant006;
