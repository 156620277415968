// NightClub001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const NightClub001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMdENEhXbU9i7ze6TJRRJupUbGGjXqJK_JMbI8D=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Hongdae Club Aura</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Hongdae Club Aura"/>
      </div>
    </div>
  );
};

export default NightClub001;
