// Coffee002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOLACy0VOUxPEXutRxI3H1pSHVIc0ZO8Pihng6Q=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Starbucks Coffee</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Starbucks Coffee"/>
      </div>
    </div>
  );
};

export default Coffee002;
