// Bar003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipORoSobuWyA1kTFY5YPUT915HZ3Bdx-7glgkQaq=w408-h544-k-no';  

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Pinche Bar</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Pinche Bar"/>
      </div>
    </div>
  );
};

export default Bar003;
