// Coffee.js
import React from 'react';
import '../category.css';
import image from './category_folders/004_Coffee/001_Coffee.JPG';
import './Alt.css'; // Import your additional CSS

const Coffee = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Coffees</div> {/* Explicitly specify the title here */}
          <img src={image} alt="787 Coffee" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Coffee;