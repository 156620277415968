// Bookstore006.js
import React from 'react';
import image from './006_Bookstore.JPG';
import './Alt.css'; // Import your additional CSS

const Bookstore006 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Club Cache</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Club Cache"/>
      </div>
    </div>
  );
};

export default Bookstore006;
