// Museum006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNi79OUicK1F0Ea7i5mCb3YVdIpeVTKM-TAJFDY=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Casa das Rosas</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Casa das Rosas"/>
      </div>
    </div>
  );
};

export default Museum006;
