import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Brands1 from './001_Brands.jsx';
import Brands2 from './002_Brands.jsx';
import Brands3 from './003_Brands.jsx';

import Brands4 from './004_Brands.jsx';
import Brands5 from './005_Brands.jsx';
import Brands6 from './006_Brands.jsx';

import Brands7 from './007_Brands.jsx';
import Brands8 from './008_Brands.jsx';
import Brands9 from './009_Brands.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Brands1 className="component" />
        <Brands2 className="component" />
        <Brands3 className="component" />
      </div>

      <div className="row">
        <Brands4 className="component" />
        <Brands5 className="component" />
        <Brands6 className="component" />
      </div>

      <div className="row">
        <Brands7 className="component" />
        <Brands8 className="component" />
        <Brands9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
