// Brands003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPEtGqxmWtG2ZfsR93zt5tIYkroGAqGO6ocxnoc=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Cuidado con el Perro</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Cuidado con el Perro"/>
      </div>
    </div>
  );
};

export default Brands003;
