// Restaurant004.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant004 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNegZTNpUj1078AIGiXf1K-Wz24EdwBQQv2FbKf=w92-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Star Samarkand</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Star Samarkand"/>
      </div>
    </div>
  );
};

export default Restaurant004;
