// Restaurant003.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipN0KShdITG56hUDJe1WjZOELhIA8v34KcRbQlXj=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Outback Steakhouse</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Outback Steakhouse"/>
      </div>
    </div>
  );
};

export default Restaurant003;
