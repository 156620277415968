// Museum006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOnxeyWSzpjxTeWs_yWbB27i0YMQ5JtZthSW8AB=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Battleship USS Iowa Museum</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Battleship USS Iowa Museum"/>
      </div>
    </div>
  );
};

export default Museum006;
