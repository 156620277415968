// Bookstore001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Bookstore001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNQ54rGFLdOaG3tOivcI5z9j5GR9J_MZiK8XLXA=w408-h283-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Librerias Gandhi</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Librerias Gandhi"/>
      </div>
    </div>
  );
};

export default Bookstore001;
