// Coffee009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNVhAGZE759xIBK_ZF0WCZFrMRej0iR1MW7bIh2=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Q on Harris</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="The Q on Harris"/>
      </div>
    </div>
  );
};

export default Coffee009;
