// Brands007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNUGqz7eHz-rfD2DzCfOPFt9jfZmkhDvE8WT0fZ=w80-h149-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">À La Garçonne</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="À La Garçonne"/>
      </div>
    </div>
  );
};

export default Brands007;
