import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Theater1 from './001_Theater.jsx';
import Theater2 from './002_Theater.jsx';
import Theater3 from './003_Theater.jsx';

import Theater4 from './004_Theater.jsx';
import Theater5 from './005_Theater.jsx';
import Theater6 from './006_Theater.jsx';

import Theater7 from './007_Theater.jsx';
import Theater8 from './008_Theater.jsx';
import Theater9 from './009_Theater.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Theater1 className="component" />
        <Theater2 className="component" />
        <Theater3 className="component" />
      </div>

      <div className="row">
        <Theater4 className="component" />
        <Theater5 className="component" />
        <Theater6 className="component" />
      </div>

      <div className="row">
        <Theater7 className="component" />
        <Theater8 className="component" />
        <Theater9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
