// Bookstore007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNi7UEpNMuaV4xQOA-a3hueYB7STNYI8OGoSkyq=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Libreria San Paolo</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Libreria San Paolo"/>
      </div>
    </div>
  );
};

export default Bookstore007;
