// NightClub001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const NightClub001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM2Fr3d1lXBPI-lsqwyp_Gtp2Yn3wznhS9VZ-Pe=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Salsa - Soho</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Salsa - Soho"/>
      </div>
    </div>
  );
};

export default NightClub001;
