// Museum005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPHVXNYewG1RP8klTaMWcLPu7_HC2H_lNNAn1bp=w408-h314-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Petersen Automotive Museum</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Petersen Automotive Museum"/>
      </div>
    </div>
  );
};

export default Museum005;
