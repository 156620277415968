// Brands009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPN1kdv6BPAfMy_0OhfzpQA-iJvmq7afkhUmk80=w408-h305-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Ilusión</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Ilusión"/>
      </div>
    </div>
  );
};

export default Brands009;
