// Bookstore009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOY0w9PlDjgYyOpgNOm6nRB0tiqXBSHg9To_kJM=w92-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Kyo Bun Kan</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Kyo Bun Kan"/>
      </div>
    </div>
  );
};

export default Bookstore009;
