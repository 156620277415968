// Coffee006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNcHobkIxoN7El7Y67QwKgpF-nnamvrbOxqQPel=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Café Habitual</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Café Habitual"/>
      </div>
    </div>
  );
};

export default Coffee006;
