// NightClub006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOJ4kRO9_i-vk5WZKxx-3myIwAfYMfVmt71HTTg=w415-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Terraza Roma</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Terraza Roma"/>
      </div>
    </div>
  );
};

export default NightClub006;
