// Bookstore006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNcMkslqk8cKdteO_QABRQof-BM3jCsFqIQTQEH=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Librería Porrúa</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Librería Porrúa"/>
      </div>
    </div>
  );
};

export default Bookstore006;
