// Restaurant008.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNzCEVL9Oi-qLBPOjg_T1djnBR5L9-wtZcYBAOd=w80-h142-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Ssada Gimbap</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Ssada Gimbap"/>
      </div>
    </div>
  );
};

export default Restaurant008;
