// Coffee003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPxLq6Z-QwUpPT121vP2AUg3j-wrTxuQuLbQKuT=w533-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sofá Café Pinheiros</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Sofá Café Pinheiros"/>
      </div>
    </div>
  );
};

export default Coffee003;
