// Bar004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMr8IUp0q2E_WrWBwNuMxdiVBrbfy6B45Rh1rGy=w408-h272-k-no';
  
  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Everson Royce Bar</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Everson Royce Bar"/>
      </div>
    </div>
  );
};

export default Bar004;
