// Bookstore005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPakLbjK8K0Y78WM3PJ6JXnV9QS_MLiUiWcBsiW=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Bookstore Fondo de Cultura Economica</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Bookstore Fondo de Cultura Economica"/>
      </div>
    </div>
  );
};

export default Bookstore005;
