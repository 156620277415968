// Theater009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNbhSuPVe5QWi9C86z8p7iy6nLqFwkHlO5wLFn2=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Haneul Theater</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Haneul Theater"/>
      </div>
    </div>
  );
};

export default Theater009;
