// Bookstore004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipO0PGabHi_Mq5RZkOpkcIH4ZjqXBxE5nlVI-LJd=w426-h240-k-no';
  
  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">El Pendulo</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="El Pendulo"/>
      </div>
    </div>
  );
};

export default Bookstore004;
