// Brands001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Brands001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMPG6nC7zVzKV295jiTDRWZycj9Ow_dEFiCTgFn=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Pull & Bear</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Pull & Bear"/>
      </div>
    </div>
  );
};

export default Brands001;
