// Bookstore006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPqVc47SFbAXPOjBX9gYRcBKP3O2YxEt67Tz7ao=w80-h142-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sanseidō</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Sanseidō"/>
      </div>
    </div>
  );
};

export default Bookstore006;
