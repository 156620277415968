// NightClub006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNfCNHmApIj6EX6iNu4WIX9BS6i5s8-j7qOGm9m=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Beton Brut</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Beton Brut"/>
      </div>
    </div>
  );
};

export default NightClub006;
