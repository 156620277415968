// Restaurant007.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant007 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM2JE7DlWywHp0f82IpqduWvBADqnfA8_SRh0ZX=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Angus Steakhouse Leicester Square</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Angus Steakhouse Leicester Square"/>
      </div>
    </div>
  );
};

export default Restaurant007;
