// NightClub001.js
import React from 'react';
import './category.css';
import image from './001_NightClub.JPG';
import './Alt.css'; // Import your additional CSS

const NightClub001 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Da Club</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Da Club"/>
      </div>
    </div>
  );
};

export default NightClub001;
