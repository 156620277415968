// Museum006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPIji_JZf4Q4p00A-gh5yQ8Whccrv99GHta696A=w92-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Madame Tussauds Sydney</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Madame Tussauds Sydney"/>
      </div>
    </div>
  );
};

export default Museum006;
