// Restaurant008.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNsMqcvjCkoOETRJlkp-Xx54JBVz3jPTLYnWiIB=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Little Snail Restaurant</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="The Little Snail Restaurant"/>
      </div>
    </div>
  );
};

export default Restaurant008;
