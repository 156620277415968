import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Museum1 from './001_Museum.jsx';
import Museum2 from './002_Museum.jsx';
import Museum3 from './003_Museum.jsx';

import Museum4 from './004_Museum.jsx';
import Museum5 from './005_Museum.jsx';
import Museum6 from './006_Museum.jsx';

import Museum7 from './007_Museum.jsx';
import Museum8 from './008_Museum.jsx';
import Museum9 from './009_Museum.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Museum1 className="component" />
        <Museum2 className="component" />
        <Museum3 className="component" />
      </div>

      <div className="row">
        <Museum4 className="component" />
        <Museum5 className="component" />
        <Museum6 className="component" />
      </div>

      <div className="row">
        <Museum7 className="component" />
        <Museum8 className="component" />
        <Museum9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
