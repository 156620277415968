// Concerts001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Concerts001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipO1B67FMtgxgXLa02wyBcWYbNUpYXjNIKBR3Ov4=w408-h305-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Dodger Stadium</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Dodger Stadium"/>
      </div>
    </div>
  );
};

export default Concerts001;
