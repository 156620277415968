// Restaurant006.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant006 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOL6gg04L6q0CrVthQoqLLyhgbgZ197_mWEJj80=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Ginza Kyūbey</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Ginza Kyūbey"/>
      </div>
    </div>
  );
};

export default Restaurant006;
