// NightClub008.js
import React from 'react';
import image from './008_NightClub.JPG';
import './Alt.css'; // Import your additional CSS

const NightClub008 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">CLUB VIZEL</div> {/* Explicitly specify the title here */}
        <img src={image} alt="CLUB VIZEL"/>
      </div>
    </div>
  );
};

export default NightClub008;
