// Museum007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNFq_Cx4or9ogmfr8DLz33p_C_E6DHSJZa2_eTF=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Hangeul National Museum</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Hangeul National Museum"/>
      </div>
    </div>
  );
};

export default Museum007;
