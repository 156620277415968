// Museum.js
import React from 'react';
import '../category.css';
import image from './category_folders/007_Museum/001_Museum.JPG';
import './Alt.css'; // Import your additional CSS

const Museum = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Museums</div> {/* Explicitly specify the title here */}  
        <img src={image} alt="MoMA" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Museum;