// Theater003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipORggRBcmnKo8Kf_XLFKPyycDz8aLf2BhxzpKTt=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Greek Theatre</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="The Greek Theatre"/>
      </div>
    </div>
  );
};

export default Theater003;
