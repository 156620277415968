// Museum005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOU-owz98bzD4OARyHUZo7l05ZLFHAGY5e8tvEP=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">National Museum of Modern Art of Tokyo</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="National Museum of Modern Art of Tokyo"/>
      </div>
    </div>
  );
};

export default Museum005;
