// Theater005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM3-ff1JPCpj9sOwRAA5bzoNz_CpPBbFVb0UGrr=w408-h297-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Ahmanson Theatre</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Ahmanson Theatre"/>
      </div>
    </div>
  );
};

export default Theater005;
