// Bar003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOvt0bkNVbLk4s8V9Zof9m-kDHWaBd7r7CMHIBu=w408-h544-k-no';  

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Pitico</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Pitico"/>
      </div>
    </div>
  );
};

export default Bar003;
