// Museum001.js
import React from 'react';
import './category.css';
import image from './001_Museum.JPG';
import './Alt.css'; // Import your additional CSS

const Museum001 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">MoMA</div> {/* Explicitly specify the title here */}
        <img src={image} alt="MoMA"/>
      </div>
    </div>
  );
};

export default Museum001;
