// Bar007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMB7zKDRzMsuBko3jwsTbNFUKy1ArflZLjjHqeq=w408-h308-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Boardner's by La Belle</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Boardner's by La Belle"/>
      </div>
    </div>
  );
};

export default Bar007;
