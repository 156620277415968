// Concerts002.js
import React from 'react';
import image from './003_Concerts.JPG';
import './Alt.css'; // Import your additional CSS

const Concerts002 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Virgo</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Virgo"/>
      </div>
    </div>
  );
};

export default Concerts002;
