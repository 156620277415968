// 001_ny.js
import React from 'react';
import '../../../App.css';
import image from './001_ny/ny.jpg';
import './Alt.css'; // Import your additional CSS

const NY = () => {

  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">NY</div> {/* Explicitly specify the title here */}
        <img src={image} alt="NY"/>
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default NY;