// Concerts004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPtfmKdj_-S4UfZTIXyVbhe6SOB0B91IhuaxhqI=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Hamarikyu Asahi Hall</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Hamarikyu Asahi Hall"/>
      </div>
    </div>
  );
};

export default Concerts004;
