// Bookstore004.js
import React from 'react';
import image from './004_Bookstore.JPG';
import './Alt.css'; // Import your additional CSS

const Bookstore004 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Rumpus Room</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Rumpus Room"/>
      </div>
    </div>
  );
};

export default Bookstore004;
