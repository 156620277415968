// Museum002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMZlHg1xIQnZ6MFeGyztJH4--C10SUGrS29QAnq=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">DIGITAL ART MUSEUM</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="DIGITAL ART MUSEUM"/>
      </div>
    </div>
  );
};

export default Museum002;
