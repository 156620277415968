// NightClub009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPyLOtXlCC8phVwlZ__Fmh45UBv0Xi3GHI0K3FM=w134-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Carwash Nightclub</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Carwash Nightclub"/>
      </div>
    </div>
  );
};

export default NightClub009;
