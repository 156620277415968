// Bookstore009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNP_nBWb4VOZVERaq-yJ0WNgVQyJp0YVhOhexUM=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Word On The Water - The London Bookbarge</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Word On The Water - The London Bookbarge"/>
      </div>
    </div>
  );
};

export default Bookstore009;
