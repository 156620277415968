// 009_mi.js
import React from 'react';
import '../../../App.css';
import image from './009_mi/mi.jpg';
import './Alt.css'; // Import your additional CSS

const MI = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">MILANO</div> {/* Explicitly specify the title here */}
          <img src={image} alt="MI" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default MI;