// Restaurant007.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant007 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipN40gFVUwHhiL1cg8h0-z9_Sj9-LfjZkMNCjkj8=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Miscusi Milano Cadorna</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Miscusi Milano Cadorna"/>
      </div>
    </div>
  );
};

export default Restaurant007;
