import React from 'react';
import { Link } from 'react-router-dom'; // If you're using React Router
import './NavBarSubTop.css'

function NavBarSubTop() {
  return (
    <nav>
      <div className="navbarsubtop">SubTOP
        {/* <Link to="/" className="logo"><App /></Link>
        <ul className="nav-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">Explore</Link></li>
          <li><Link to="/">Profile</Link></li>
          <li><Link to="/">messages</Link></li>
        </ul>*/}
      </div>
    </nav>
  );
}

export default NavBarSubTop;
