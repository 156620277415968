// Concerts004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNHC8edSEUbUamp7zHMqX25zZ2Ew3tp9jkioOJk=w189-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">MUV-Hall</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="MUV-Hall"/>
      </div>
    </div>
  );
};

export default Concerts004;
