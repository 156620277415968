// Brands008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMRo_Hqfk0lQ8m4o6an2uBxSvsHhiSFChks7_4w=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Barneys New York</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Barneys New York"/>
      </div>
    </div>
  );
};

export default Brands008;
