// Concerts003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipN_Y3a0O_AtRtlripkwAN9Fu1CWiw1gMbwDaqwT=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Tokyo Opera City Concert Hall</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Tokyo Opera City Concert Hall"/>
      </div>
    </div>
  );
};

export default Concerts003;
