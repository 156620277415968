// NightClub007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMWfALLDjMXlmTy7FIbO7MVzhZN80o0x-B8gG3f=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Joy Room</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Joy Room"/>
      </div>
    </div>
  );
};

export default NightClub007;
