// Coffee005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipN7RJX9nm4UErqQZNW27ULpfWBLWb_QNlA8n_Gt=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Caffè Cimmino</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Caffè Cimmino"/>
      </div>
    </div>
  );
};

export default Coffee005;
