import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import NightClub1 from './001_NightClub.jsx';
import NightClub2 from './002_NightClub.jsx';
import NightClub3 from './003_NightClub.jsx';

import NightClub4 from './004_NightClub.jsx';
import NightClub5 from './005_NightClub.jsx';
import NightClub6 from './006_NightClub.jsx';

import NightClub7 from './007_NightClub.jsx';
import NightClub8 from './008_NightClub.jsx';
import NightClub9 from './009_NightClub.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <NightClub1 className="component" />
        <NightClub2 className="component" />
        <NightClub3 className="component" />
      </div>

      <div className="row">
        <NightClub4 className="component" />
        <NightClub5 className="component" />
        <NightClub6 className="component" />
      </div>

      <div className="row">
        <NightClub7 className="component" />
        <NightClub8 className="component" />
        <NightClub9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
