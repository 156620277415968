// Bar002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMeJji8cln8KIwF9Lm9qNYG8GyBd2KzuSaq0aI=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Terraza Madero</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Terraza Madero"/>
      </div>
    </div>
  );
};

export default Bar002;
