import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Bookstore1 from './001_Bookstore.jsx';
import Bookstore2 from './002_Bookstore.jsx';
import Bookstore3 from './003_Bookstore.jsx';

import Bookstore4 from './004_Bookstore.jsx';
import Bookstore5 from './005_Bookstore.jsx';
import Bookstore6 from './006_Bookstore.jsx';

import Bookstore7 from './007_Bookstore.jsx';
import Bookstore8 from './008_Bookstore.jsx';
import Bookstore9 from './009_Bookstore.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Bookstore1 className="component" />
        <Bookstore2 className="component" />
        <Bookstore3 className="component" />
      </div>

      <div className="row">
        <Bookstore4 className="component" />
        <Bookstore5 className="component" />
        <Bookstore6 className="component" />
      </div>

      <div className="row">
        <Bookstore7 className="component" />
        <Bookstore8 className="component" />
        <Bookstore9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
