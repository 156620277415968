// Bar.js
import React from 'react';
import '../category.css';
import './Alt.css'; // Import your additional CSS

const Bar = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNt7lfySUrcSWWjaioj4uCASENf5wtl14c7i7Ul=w408-h271-k-no';  

  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Bar</div> {/* Explicitly specify the title here */}
          <img src={imageUrl} alt="Bar" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Bar;