// Restaurant002.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant002 = () => {

  const imageUrl = 'https://lh5.googleusercontent.com/p/AF1QipPlTVYg_0qU-gdPS4FARrAwdoZlVUHZ84MSs8Yu=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Vapiano King Street</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Vapiano King Street"/>
      </div>
    </div>
  );
};

export default Restaurant002;
