// Coffee007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNsYFC7obXdVQO4y9Sfy8eiqaM-dqSYrwGIGvo4=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Happy Churro</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="The Happy Churro"/>
      </div>
    </div>
  );
};

export default Coffee007;
