import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Bar1 from './001_Bar.jsx';
import Bar2 from './002_Bar.jsx';
import Bar3 from './003_Bar.jsx';

import Bar4 from './004_Bar.jsx';
import Bar5 from './005_Bar.jsx';
import Bar6 from './006_Bar.jsx';

import Bar7 from './007_Bar.jsx';
import Bar8 from './008_Bar.jsx';
import Bar9 from './009_Bar.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Bar1 className="component" />
        <Bar2 className="component" />
        <Bar3 className="component" />
      </div>

      <div className="row">
        <Bar4 className="component" />
        <Bar5 className="component" />
        <Bar6 className="component" />
      </div>

      <div className="row">
        <Bar7 className="component" />
        <Bar8 className="component" />
        <Bar9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
