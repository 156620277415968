// Museum001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Museum001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOuc6xOF4QAgJLjt3Sfh_Ry2bO9nuRtv-rr4Szb=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">National Museum of Korea</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="National Museum of Korea"/>
      </div>
    </div>
  );
};

export default Museum001;
