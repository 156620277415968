// NightClub009.js
import React from 'react';
import image from './009_NightClub.JPG';
import './Alt.css'; // Import your additional CSS

const NightClub009 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Club Camelot</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Club Camelot"/>
      </div>
    </div>
  );
};

export default NightClub009;
