// Coffee009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPDZdCQn-nezx60Khu_7LKzwmsRn2IUYxKpAciE=w426-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Cafe Avellaneda</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Cafe Avellaneda"/>
      </div>
    </div>
  );
};

export default Coffee009;
