// Restaurant009.js
import React from 'react';
import './category.css';
import image from './009_Restaurant.JPG';
import './Alt.css'; // Import your additional CSS

const Restaurant009 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Piccola Cucina</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Piccola Cucina"/>
      </div>
    </div>
  );
};

export default Restaurant009;
