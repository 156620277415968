// Theater005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM9_eyFgip_bO2dvRMmH-kuazPLP-dS4x6-se87=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">EX THEATER ROPPONGI</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="EX THEATER ROPPONGI"/>
      </div>
    </div>
  );
};

export default Theater005;
