// Museum001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Museum001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNvmu5p3m2kCiiiw5onxHXpMYS_ZxZouEFHzRT3=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">British Museum</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="British Museum"/>
      </div>
    </div>
  );
};

export default Museum001;
