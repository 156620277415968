// Concerts007.js
import React from 'react';
import image from './007_Concerts.JPG';
import './Alt.css'; // Import your additional CSS

const Concerts007 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Outer Heaven</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Outer Heaven"/>
      </div>
    </div>
  );
};

export default Concerts007;
