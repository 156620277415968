// 004_mx.js
import React from 'react';
import '../../../App.css';
import image from './004_mx/mx.jpg';
import './Alt.css'; // Import your additional CSS

const MX = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">MEXICO DF</div> {/* Explicitly specify the title here */}
          <img src={image} alt="MX" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default MX;