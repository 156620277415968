// 007_ln.js
import React from 'react';
import '../../../App.css';
import image from './007_ln/ln.jpg';
import './Alt.css'; // Import your additional CSS

const LN = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">LONDON</div> {/* Explicitly specify the title here */}
          <img src={image} alt="LN" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default LN;