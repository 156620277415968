// Brands009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM7LM3iyq4digwO8h85i7EBPgb6RHvQCu60fio3=w408-h699-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sisters Clothes</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Sisters Clothes"/>
      </div>
    </div>
  );
};

export default Brands009;
