// Restaurant004.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant004 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipO9KRaUPnVfoqWjIbM5_7AnxqJ7CjQenQLAP2WJ=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Elements Smokehouse and Bar</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Elements Smokehouse and Bar"/>
      </div>
    </div>
  );
};

export default Restaurant004;
