// Restaurant005.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant005 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMBTpuDZ02LeTG_DEr6fXeOBD1eXm8o-REwRGGq=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sherlock Holmes</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Sherlock Holmes"/>
      </div>
    </div>
  );
};

export default Restaurant005;
