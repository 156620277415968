// Bookstore006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOevIXWFfARHn2UcRT2VWwFhfVT66HfMk7WC5ys=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Livraria CPAD</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Livraria CPAD"/>
      </div>
    </div>
  );
};

export default Bookstore006;
