// NightClub002.js
import React from 'react';
import image from './002_NightClub.JPG';
import './Alt.css'; // Import your additional CSS

const NightClub002 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Tropicana Tokyo</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Tropicana Tokyo"/>
      </div>
    </div>
  );
};

export default NightClub002;
