// Restaurant002.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant002 = () => {

  const imageUrl = 'https://lh5.googleusercontent.com/p/AF1QipPmbW-AKsieSjC89F7W0rn9neygNp7Zipsn_mTh=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Fresco & Cimmino</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Fresco & Cimmino"/>
      </div>
    </div>
  );
};

export default Restaurant002;
