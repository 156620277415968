// Bookstore009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipO_6N_MelZGJJO863sJScg_5o96XkIH-be6w90=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Berkelouw Paddington</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Berkelouw Paddington"/>
      </div>
    </div>
  );
};

export default Bookstore009;
