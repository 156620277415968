// Theater002.js
import React from 'react';
import image from './002_Theater.JPG';
import './Alt.css'; // Import your additional CSS

const Theater002 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Box</div> {/* Explicitly specify the title here */}
        <img src={image} alt="The Box"/>
      </div>
    </div>
  );
};

export default Theater002;
