// Bar003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar003 = () => {

  const imageUrl ='https://lh3.googleusercontent.com/gps-proxy/ALd4DhEjz7FGv3JeovaJTQutmlHwyDWWdp1xda53CIygkNM9lD_jlZSO_Iu_hJKoYkWoZVD8TqH8lV3f0Vpw8SQPw8bMUIcSzrtpz3HI3HRFgIQbIyHAFjHabHy5DRJLO7XZMI7G6K6IpSZTNMGIwMMHRZt9IfStSxwG8PaVKoMKqNjq0x4etIIT5KkBNQ=w408-h253-k-no';  

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Good Times at Davey Wayne's</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Good Times at Davey Wayne's"/>
      </div>
    </div>
  );
};

export default Bar003;
