// Brands008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMU6nCbWX4N9IW-GZ0j79XOuZqsrAWBwoyvbVQ9=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">New Look</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="New Look"/>
      </div>
    </div>
  );
};

export default Brands008;
