// Bar002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMNB_J2Kre0diAfTv86iYXmZYzWlbPs9velhVU2=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">IBREW GINZA</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="IBREW GINZA"/>
      </div>
    </div>
  );
};

export default Bar002;
