// Brands002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipP65oydacaO2zG6amI5-B2hhiVyY_RlJwZxriP8=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">UNIQLO</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="UNIQLO"/>
      </div>
    </div>
  );
};

export default Brands002;
