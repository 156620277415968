// 003_la.js
import React from 'react';
import '../../../App.css';
import image from './003_la/la.jpg';
import './Alt.css'; // Import your additional CSS

const LA = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">LA</div> {/* Explicitly specify the title here */}
          <img src={image} alt="LA" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default LA;