// Bookstore009.js
import React from 'react';
import image from './009_Bookstore.JPG';
import './Alt.css'; // Import your additional CSS

const Bookstore009 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Loosies</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Loosies"/>
      </div>
    </div>
  );
};

export default Bookstore009;
