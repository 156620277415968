// Bar004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPeS1XDzjDWobO6Qb50YDmjl8p-ZfnwM6-zVGte=w122-h92-k-no';
  
  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Ginza 300</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Ginza 300"/>
      </div>
    </div>
  );
};

export default Bar004;
