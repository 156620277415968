// Bookstore006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOLckV1eUarn-ZC9n3KatzEwmAiqhep3x9kt2iM=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Abbey's Bookshop</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Abbey's Bookshop"/>
      </div>
    </div>
  );
};

export default Bookstore006;
