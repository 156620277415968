// Bar001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Bar001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM9ETH5vRYALEYGCYU5vhoh1paIo3QlxoEWWTQ=w408-h271-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">La Cerveceria de Barrio-Alameda</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="La Cerveceria de Barrio-Alameda"/>
      </div>
    </div>
  );
};

export default Bar001;
