// Bookstore007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOy_VrvUOiV4rEMAHyQRX2Ko8_PijGVsrALyL0v=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">생명의말씀사 광화문점</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="생명의말씀사 광화문점"/>
      </div>
    </div>
  );
};

export default Bookstore007;
