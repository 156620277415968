// Theater008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPTfBHAyb7KoJt42oFZUj8BOMVvZwT5TCV4D1AJ=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Teatro Procópio Ferreira</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Teatro Procópio Ferreira"/>
      </div>
    </div>
  );
};

export default Theater008;
