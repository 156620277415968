import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Concerts1 from './001_Concerts.jsx';
import Concerts2 from './002_Concerts.jsx';
import Concerts3 from './003_Concerts.jsx';

import Concerts4 from './004_Concerts.jsx';
import Concerts5 from './005_Concerts.jsx';
import Concerts6 from './006_Concerts.jsx';

import Concerts7 from './007_Concerts.jsx';
import Concerts8 from './008_Concerts.jsx';
import Concerts9 from './009_Concerts.jsx';

function NightClubPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Concerts1 className="component" />
        <Concerts2 className="component" />
        <Concerts3 className="component" />
      </div>

      <div className="row">
        <Concerts4 className="component" />
        <Concerts5 className="component" />
        <Concerts6 className="component" />
      </div>

      <div className="row">
        <Concerts7 className="component" />
        <Concerts8 className="component" />
        <Concerts9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default NightClubPlaces;
