// Coffee001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Coffee001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPAY-Jr-Itzrgn6Dju7D0fC20wy4MXSnAUYHbz8=w122-h92-k-no';  

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Doutor</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Doutor"/>
      </div>
    </div>
  );
};

export default Coffee001;
