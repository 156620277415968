// Restaurant004.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Restaurant004 = () => {

const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPw6coACbPYQh3VKSOkp0esLuNdhH7-j1ozGqyj=w408-h252-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Hard Rock Cafe</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Hard Rock Cafe"/>
      </div>
    </div>
  );
};

export default Restaurant004;
