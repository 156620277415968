// Coffee002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNBYYjw66db6JA3oJdPWbPAnmsj4EnYCkot9ykO=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Salem Witch</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Salem Witch"/>
      </div>
    </div>
  );
};

export default Coffee002;
