// Restaurant.js
import React from 'react';
import '../category.css';
import image from './category_folders/002_Restaurant/001_Restaurant.JPG';
import { useNavigate } from 'react-router-dom';
import category from '../category.jsx'
import './Alt.css'; // Import your additional CSS

const Restaurant = () => {

  let navigate = useNavigate();

  const handleClick = () => {
    navigate(category);
  };

  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Restaurants</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Max Restaurant" onClick={handleClick}/>
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Restaurant;