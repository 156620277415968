// Theater001.js
import React from 'react';
import './category.css';
import './Alt.css'; // Import your additional CSS

const Theater001 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPhOj47rQ-x1PyKFkjd4Zu3nsDAkURS_y066Gpn=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sydney Opera</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Sydney Opera"/>
      </div>
    </div>
  );
};

export default Theater001;
