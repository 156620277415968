// Brands003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOaPRxgyoPan-f12JAZzuFM56N39XsrY9LThOY8=w80-h142-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Urban Outfitters</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Urban Outfitters"/>
      </div>
    </div>
  );
};

export default Brands003;
