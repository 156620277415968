// Concerts002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMdA_AnFzraiHGe6Qm4oJo6Az-7TQ53GH80Gsok=w163-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">YES 24 Live Hall</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="YES 24 Live Hall"/>
      </div>
    </div>
  );
};

export default Concerts002;
