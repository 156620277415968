// Brands.js
import React from 'react';
import '../category.css';
import image from './category_folders/009_Brands/001_Brands.JPG';
import './Alt.css'; // Import your additional CSS

const Brands = () => {
  return (
    <div className="container">
      {/* Your posts go here */}
        <div className="post">
        <div className="title-overlay">Brands</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Versace" />
          {/*
            <div className="post-info">
              <h2>Post Title</h2>
              <p>Post description goes here...</p>
            </div>
          */}
        </div>
    </div>
  );
}

export default Brands;