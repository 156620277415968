// Theater007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipM4GyrlRbnBTdi0WjAY1k1HNP5Eyu7fPRgSpNXZ=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Theatre Royal Sydney</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Theatre Royal Sydney"/>
      </div>
    </div>
  );
};

export default Theater007;
