// Concerts004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNGMWEW4HTR5LH-J8u8U2j0OXqO8sz-mJJaDpM=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Sala Nezahualcoyotl</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Parque Sala Nezahualcoyotl"/>
      </div>
    </div>
  );
};

export default Concerts004;
