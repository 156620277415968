// NightClub003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPK5f6pexl2wseL72Tjmlifk6QcASRr-i_9c--s=w426-h240-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Los Globos</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Los Globos"/>
      </div>
    </div>
  );
};

export default NightClub003;
