// Bookstore002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bookstore002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPmPHzAJc2mh6JzTJU41cQi5efm1sr-zEGsL0-W=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Youngpoong Bookstore</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Youngpoong Bookstore"/>
      </div>
    </div>
  );
};

export default Bookstore002;
