// NightClub.js
import React from 'react';
import '../category.css';
import image from './category_folders/001_NightClub/001_NightClub.JPG';
import './Alt.css'; // Import your additional CSS

const NightClub = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Night Clubs</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Da Club" />
      </div>
    </div>
  );
};

export default NightClub;
