// Coffee005.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Coffee005 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMIH0VWcAy-rKarjkkKWYyghkdy5G7mQ3Kafllq=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Minichelista</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Minichelista"/>
      </div>
    </div>
  );
};

export default Coffee005;
