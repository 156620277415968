import React from 'react';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import Restaurant1 from './001_Restaurant.jsx';
import Restaurant2 from './002_Restaurant.jsx';
import Restaurant3 from './003_Restaurant.jsx';

import Restaurant4 from './004_Restaurant.jsx';
import Restaurant5 from './005_Restaurant.jsx';
import Restaurant6 from './006_Restaurant.jsx';

import Restaurant7 from './007_Restaurant.jsx';
import Restaurant8 from './008_Restaurant.jsx';
import Restaurant9 from './009_Restaurant.jsx';

function RestaturantPlaces() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Restaurant1 className="component" />
        <Restaurant2 className="component" />
        <Restaurant3 className="component" />
      </div>

      <div className="row">
        <Restaurant4 className="component" />
        <Restaurant5 className="component" />
        <Restaurant6 className="component" />
      </div>

      <div className="row">
        <Restaurant7 className="component" />
        <Restaurant8 className="component" />
        <Restaurant9 className='component' />
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default RestaturantPlaces;
