// Bar009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Bar009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOqfEHqQEuMPszFrQqtJ1Kr5zFhteRQii5IrVBj=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">3 Wise Monkeys Pub</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="3 Wise Monkeys Pub"/>
      </div>
    </div>
  );
};

export default Bar009;
