// Museum003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNvpH1TA_TTLmG6vsLgGbSBD6laECqt9KD9Kp8W=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Museo Nacional de Antropología</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Museo Nacional de Antropología"/>
      </div>
    </div>
  );
};

export default Museum003;
