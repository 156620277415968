// Theater008.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater008 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPawxUPntRWCEjjV2es17oddWS7at-fVYevYB-w=w80-h106-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Metro Theatre</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Metro Theatre"/>
      </div>
    </div>
  );
};

export default Theater008;
