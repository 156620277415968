// Brands005.js
import React from 'react';
import image from './005_Brands.JPG';
import './Alt.css'; // Import your additional CSS

const Brands005 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Deluxx Fluxx</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Deluxx Fluxx"/>
      </div>
    </div>
  );
};

export default Brands005;
