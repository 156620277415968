// Concerts002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPahrOxPo0ZOgOdiiIPVAs8Z2bJf9bQOvf_PWnE=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Oxford Art Factory</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Oxford Art Factory"/>
      </div>
    </div>
  );
};

export default Concerts002;
