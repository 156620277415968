// Brands001.js
import React from 'react';
import './category.css';
import image from './001_Brands.JPG';
import './Alt.css'; // Import your additional CSS

const Brands001 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Versace</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Versace"/>
      </div>
    </div>
  );
};

export default Brands001;
