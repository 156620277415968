// NightClub007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipN1nyLu8BR4eU224mPgs9ykFiL0tfrSuShB4-6Z=w138-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Soho Zebrano</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Soho Zebrano"/>
      </div>
    </div>
  );
};

export default NightClub007;
