// Concerts006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipMSZ9GGsGBkN2RabboGWJO8Xj_yXPCpPBvlJQ8V=w408-h544-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Bourbon Street Music Club</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Bourbon Street Music Club"/>
      </div>
    </div>
  );
};

export default Concerts006;
