import React, { useContext } from 'react';
import { Link } from 'react-router-dom';


function NavBarBottom() {
  return (
    <nav>
      <div className="navbarbottom">
        {/* <Link to="/" className="logo"><App /></Link>
        <ul className="nav-links">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/">Explore</Link></li>
          <li><Link to="/">Profile</Link></li>
          <li><Link to="/">messages</Link></li>
        </ul>*/}
      </div>
    </nav>
  );
}

export default NavBarBottom;
