import React from 'react';
import { Link } from 'react-router-dom';
import './category.css';
import './NavBarBottom.css';
import './NavBarTop.css';
import './NavBarSubTop.css';

import NavBarTop from './NavBarTop.jsx';
import NavBarSubTop from './NavBarSubTop.jsx';
import NavBarBottom from './NavBarBottom.jsx';

import NightClub from './category/001_NightClub.jsx';
import Restaurant from './category/002_Restaurant.jsx';
import Bar from './category/003_Bar.jsx';

import Coffee from './category/004_Coffee.jsx';
import Library from './category/005_Library.jsx';
import Theater from './category/006_Theater.jsx';

import Museum from './category/007_Museum.jsx';
import Concerts from './category/008_Concerts.jsx';
import Brands from './category/009_Brands.jsx';

function Category() {
  return (
    <div className='Category'>
      <div className="row">
        <NavBarTop className="navbartop"/>
      </div>

      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>

      <div className="row">
        <Link to="/nightclub-places-004MX"><NightClub className="component" /></Link>
        <Link to="/restaurant-places-004MX"><Restaurant className="component" /></Link>
        <Link to="/bar-places-004MX"><Bar className="component" /></Link>
      </div>

      <div className="row">
        <Link to="/coffee-places-004MX"><Coffee className="component" /></Link>
        <Link to="/bookstore-places-004MX"><Library className="component" /></Link>
        <Link to="/theater-places-004MX"><Theater className="component" /></Link>
      </div>

      <div className="row">
        <Link to="/museum-places-004MX"><Museum className="component" /></Link>
        <Link to="/concert-places-004MX"><Concerts className="component" /></Link>
        <Link to="/brand-places-004MX"><Brands className='component' /></Link>
      </div>

      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default Category;
