// Concerts009.js
import React from 'react';
import image from './009_Concerts.JPG';
import './Alt.css'; // Import your additional CSS

const Concerts009 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Loosies</div> {/* Explicitly specify the title here */}
        <img src={image} alt="Loosies"/>
      </div>
    </div>
  );
};

export default Concerts009;
