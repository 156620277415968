// Theater009.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Theater009 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipNw1ymk01L7i-rzhl6CFefaNaS3hWWj3EuMzsoX=w320-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Teatro das Artes</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Teatro das Artes"/>
      </div>
    </div>
  );
};

export default Theater009;
