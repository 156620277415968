// Museum004.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Museum004 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOhpMx34W6yJ7gy6oGRk2A3MhmESeKCvB8O9Yji=w92-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Victoria and Albert Museum</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Victoria and Albert Museum"/>
      </div>
    </div>
  );
};

export default Museum004;
