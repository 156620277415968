// Brands006.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Brands006 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPZKmDnUluP7ykTyqubD8GIV1Meges2K3mxzgkR=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">UNIQLO The BLOC</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="UNIQLO The BLOC"/>
      </div>
    </div>
  );
};

export default Brands006;
