// Concerts003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const Concerts003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPTxGVB7ivLVT3Niev0UIhV3UPmoG5eYCCWvOy2=w408-h306-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Hollywood Bowl</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Hollywood Bowl"/>
      </div>
    </div>
  );
};

export default Concerts003;
