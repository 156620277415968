// NightClub003.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub003 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPdW3bkem6j7-jJHdxyRmoHUYf1p2qrYTzRdJoa=w131-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Side Bar</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Side Bar"/>
      </div>
    </div>
  );
};

export default NightClub003;
