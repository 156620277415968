// NightClub002.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub002 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipOnIdvIN-3yDtOTBtBBnHo3mJ2zql8MJ0glPpN8=w122-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">Octagon Club</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="Octagon Club"/>
      </div>
    </div>
  );
};

export default NightClub002;
