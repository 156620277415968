import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import '../../components/NavBarBottom.css';
import '../../components/NavBarTop.css';
import '../../components/NavBarSubTop.css';

import NavBarTop from '../../components/NavBarTop.jsx';
import NavBarSubTop from '../../components/NavBarSubTop.jsx';
import NavBarBottom from '../../components/NavBarBottom.jsx';

import NY from './city/001_ny.jsx';
import TK from './city/002_tk.jsx';
import LA from './city/003_la.jsx';

import MX from './city/004_mx.jsx';
import SE from './city/005_se.jsx';
import SP from './city/006_sp.jsx';

import LN from './city/007_ln.jsx';
import SY from './city/008_sy.jsx';
import MI from './city/009_mi.jsx';

function City() {
  return (
    <div className='City'>
      <div className="row">
        <NavBarTop className="navartop"/>
      </div>
      <div className="row">
        <NavBarSubTop className="navbarsubtop"/>
      </div>
      <div className="row">
        <Link to="/category-ny"><NY className="component"/></Link>
        <Link to="/category-tk"><TK className="component"/></Link>
        <Link to="/category-la"><LA className="component"/></Link>
      </div>

      <div className="row">
        <Link to="/category-mx"><MX className="component" /></Link>
        <Link to="/category-se"><SE className="component" /></Link>
        <Link to="/category-sp"><SP className="component" /></Link>
      </div>
      <div className="row">
        <Link to="/category-ln"><LN className="component" /></Link>
        <Link to="/category-sy"><SY className="component" /></Link>      
        <Link to="/category-mi"><MI className="component" /></Link>
      </div>
      <div className="row">
        <NavBarBottom className="navbarbottom"/>
      </div>
    </div>
  );
}

export default City;
