// Restaurant007.js
import React from 'react';
import './category.css';
import image from './007_Restaurant.JPG';
import './Alt.css'; // Import your additional CSS

const Restaurant007 = () => {

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">The Odeon</div> {/* Explicitly specify the title here */}
        <img src={image} alt="The Odeon"/>
      </div>
    </div>
  );
};

export default Restaurant007;
