import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import City from './components/home/city.jsx'

// NAV PLACES 001NY

import NightClubPlaces001NY from './components/home/city/001_ny/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces001NY from './components/home/city/001_ny/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces001NY from './components/home/city/001_ny/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces001NY from './components/home/city/001_ny/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces001NY from './components/home/city/001_ny/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces001NY from './components/home/city/001_ny/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces001NY from './components/home/city/001_ny/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces001NY from './components/home/city/001_ny/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces001NY from './components/home/city/001_ny/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 002TK

import NightClubPlaces002TK from './components/home/city/002_tk/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces002TK from './components/home/city/002_tk/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces002TK from './components/home/city/002_tk/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces002TK from './components/home/city/002_tk/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces002TK from './components/home/city/002_tk/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces002TK from './components/home/city/002_tk/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces002TK from './components/home/city/002_tk/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces002TK from './components/home/city/002_tk/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces002TK from './components/home/city/002_tk/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 003LA

import NightClubPlaces003LA from './components/home/city/003_la/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces003LA from './components/home/city/003_la/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces003LA from './components/home/city/003_la/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces003LA from './components/home/city/003_la/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces003LA from './components/home/city/003_la/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces003LA from './components/home/city/003_la/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces003LA from './components/home/city/003_la/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces003LA from './components/home/city/003_la/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces003LA from './components/home/city/003_la/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 004MX

import NightClubPlaces004MX from './components/home/city/004_mx/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces004MX from './components/home/city/004_mx/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces004MX from './components/home/city/004_mx/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces004MX from './components/home/city/004_mx/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces004MX from './components/home/city/004_mx/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces004MX from './components/home/city/004_mx/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces004MX from './components/home/city/004_mx/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces004MX from './components/home/city/004_mx/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces004MX from './components/home/city/004_mx/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 005SE

import NightClubPlaces005SE from './components/home/city/005_se/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces005SE from './components/home/city/005_se/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces005SE from './components/home/city/005_se/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces005SE from './components/home/city/005_se/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces005SE from './components/home/city/005_se/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces005SE from './components/home/city/005_se/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces005SE from './components/home/city/005_se/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces005SE from './components/home/city/005_se/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces005SE from './components/home/city/005_se/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 006SP

import NightClubPlaces006SP from './components/home/city/006_sp/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces006SP from './components/home/city/006_sp/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces006SP from './components/home/city/006_sp/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces006SP from './components/home/city/006_sp/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces006SP from './components/home/city/006_sp/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces006SP from './components/home/city/006_sp/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces006SP from './components/home/city/006_sp/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces006SP from './components/home/city/006_sp/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces006SP from './components/home/city/006_sp/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 007LN

import NightClubPlaces007LN from './components/home/city/007_ln/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces007LN from './components/home/city/007_ln/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces007LN from './components/home/city/007_ln/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces007LN from './components/home/city/007_ln/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces007LN from './components/home/city/007_ln/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces007LN from './components/home/city/007_ln/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces007LN from './components/home/city/007_ln/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces007LN from './components/home/city/007_ln/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces007LN from './components/home/city/007_ln/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 008SY

import NightClubPlaces008SY from './components/home/city/008_sy/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces008SY from './components/home/city/008_sy/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces008SY from './components/home/city/008_sy/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces008SY from './components/home/city/008_sy/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces008SY from './components/home/city/008_sy/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces008SY from './components/home/city/008_sy/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces008SY from './components/home/city/008_sy/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces008SY from './components/home/city/008_sy/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces008SY from './components/home/city/008_sy/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV PLACES 009MI

import NightClubPlaces009MI from './components/home/city/009_mi/category/category_folders/001_NightClub/NightClubPlaces.jsx'; 
import RestaturantPlaces009MI from './components/home/city/009_mi/category/category_folders/002_Restaurant/RestaturantPlaces.jsx'; 
import BarPlaces009MI from './components/home/city/009_mi/category/category_folders/003_Bar/BarPlaces.jsx'; 

import CoffeePlaces009MI from './components/home/city/009_mi/category/category_folders/004_Coffee/CoffeePlaces.jsx'; 
import BookstorePlaces009MI from './components/home/city/009_mi/category/category_folders/005_Library/BookstorePlaces.jsx'; 
import TheaterPlaces009MI from './components/home/city/009_mi/category/category_folders/006_Theater/TheaterPlaces.jsx'; 

import MuseumPlaces009MI from './components/home/city/009_mi/category/category_folders/007_Museum/MuseumPlaces.jsx'; 
import ConcertsPlaces009MI from './components/home/city/009_mi/category/category_folders/008_Concerts/ConcertsPlaces.jsx'; 
import BrandsPlaces009MI from './components/home/city/009_mi/category/category_folders/009_Brands/BrandsPlaces.jsx'; 

// NAV CATEGORIES

import CategoryNY from './components/home/city/001_ny/category.jsx'; // Import the CategoryNY component
import CategoryTK from './components/home/city/002_tk/category.jsx'; // Import the CategoryNY component
import CategoryLA from './components/home/city/003_la/category.jsx'; // Import the CategoryNY component

import CategoryMX from './components/home/city/004_mx/category.jsx'; // Import the CategoryNY component
import CategorySE from './components/home/city/005_se/category.jsx'; // Import the CategoryNY component
import CategorySP from './components/home/city/006_sp/category.jsx'; // Import the CategoryNY component

import CategoryLN from './components/home/city/007_ln/category.jsx'; // Import the CategoryNY component
import CategorySY from './components/home/city/008_sy/category.jsx'; // Import the CategoryNY component
import CategoryMI from './components/home/city/009_mi/category.jsx'; // Import the CategoryNY component


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<City />} />
        <Route path="/category-ny" element={<CategoryNY />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-tk" element={<CategoryTK />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-la" element={<CategoryLA />} /> {/* Define the route for CategoryNY */}

        <Route path="/category-mx" element={<CategoryMX />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-se" element={<CategorySE />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-sp" element={<CategorySP />} /> {/* Define the route for CategoryNY */}

        <Route path="/category-ln" element={<CategoryLN />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-sy" element={<CategorySY />} /> {/* Define the route for CategoryNY */}
        <Route path="/category-mi" element={<CategoryMI />} /> {/* Define the route for CategoryNY */}
      
        // NAV PLACES 001NY

        <Route path="/nightclub-places-001NY" element={<NightClubPlaces001NY />} />
        <Route path="/restaurant-places-001NY" element={<RestaturantPlaces001NY />} />
        <Route path="/bar-places-001NY" element={<BarPlaces001NY />} />

        <Route path="/coffee-places-001NY" element={<CoffeePlaces001NY />} />
        <Route path="/bookstore-places-001NY" element={<BookstorePlaces001NY />} />
        <Route path="/theater-places-001NY" element={<TheaterPlaces001NY />} />

        <Route path="/museum-places-001NY" element={<MuseumPlaces001NY />} />
        <Route path="/concert-places-001NY" element={<ConcertsPlaces001NY />} />
        <Route path="/brand-places-001NY" element={<BrandsPlaces001NY />} />

        // NAV PLACES 002TK

        <Route path="/nightclub-places-002TK" element={<NightClubPlaces002TK />} />
        <Route path="/restaurant-places-002TK" element={<RestaturantPlaces002TK />} />
        <Route path="/bar-places-002TK" element={<BarPlaces002TK />} />

        <Route path="/coffee-places-002TK" element={<CoffeePlaces002TK />} />
        <Route path="/bookstore-places-002TK" element={<BookstorePlaces002TK />} />
        <Route path="/theater-places-002TK" element={<TheaterPlaces002TK />} />

        <Route path="/museum-places-002TK" element={<MuseumPlaces002TK />} />
        <Route path="/concert-places-002TK" element={<ConcertsPlaces002TK />} />
        <Route path="/brand-places-002TK" element={<BrandsPlaces002TK />} />

        // NAV PLACES 003LA

        <Route path="/nightclub-places-003LA" element={<NightClubPlaces003LA />} />
        <Route path="/restaurant-places-003LA" element={<RestaturantPlaces003LA />} />
        <Route path="/bar-places-003LA" element={<BarPlaces003LA />} />

        <Route path="/coffee-places-003LA" element={<CoffeePlaces003LA />} />
        <Route path="/bookstore-places-003LA" element={<BookstorePlaces003LA />} />
        <Route path="/theater-places-003LA" element={<TheaterPlaces003LA />} />

        <Route path="/museum-places-003LA" element={<MuseumPlaces003LA />} />
        <Route path="/concert-places-003LA" element={<ConcertsPlaces003LA />} />
        <Route path="/brand-places-003LA" element={<BrandsPlaces003LA />} />

        // NAV PLACES 004MX

        <Route path="/nightclub-places-004MX" element={<NightClubPlaces004MX />} />
        <Route path="/restaurant-places-004MX" element={<RestaturantPlaces004MX />} />
        <Route path="/bar-places-004MX" element={<BarPlaces004MX />} />

        <Route path="/coffee-places-004MX" element={<CoffeePlaces004MX />} />
        <Route path="/bookstore-places-004MX" element={<BookstorePlaces004MX />} />
        <Route path="/theater-places-004MX" element={<TheaterPlaces004MX />} />

        <Route path="/museum-places-004MX" element={<MuseumPlaces004MX />} />
        <Route path="/concert-places-004MX" element={<ConcertsPlaces004MX />} />
        <Route path="/brand-places-004MX" element={<BrandsPlaces004MX />} />

        // NAV PLACES 005SE

        <Route path="/nightclub-places-005SE" element={<NightClubPlaces005SE />} />
        <Route path="/restaurant-places-005SE" element={<RestaturantPlaces005SE />} />
        <Route path="/bar-places-005SE" element={<BarPlaces005SE />} />

        <Route path="/coffee-places-005SE" element={<CoffeePlaces005SE />} />
        <Route path="/bookstore-places-005SE" element={<BookstorePlaces005SE />} />
        <Route path="/theater-places-005SE" element={<TheaterPlaces005SE />} />

        <Route path="/museum-places-005SE" element={<MuseumPlaces005SE />} />
        <Route path="/concert-places-005SE" element={<ConcertsPlaces005SE />} />
        <Route path="/brand-places-005SE" element={<BrandsPlaces005SE />} />
        
        // NAV PLACES 006SP

        <Route path="/nightclub-places-006SP" element={<NightClubPlaces006SP />} />
        <Route path="/restaurant-places-006SP" element={<RestaturantPlaces006SP />} />
        <Route path="/bar-places-006SP" element={<BarPlaces006SP />} />

        <Route path="/coffee-places-006SP" element={<CoffeePlaces006SP />} />
        <Route path="/bookstore-places-006SP" element={<BookstorePlaces006SP />} />
        <Route path="/theater-places-006SP" element={<TheaterPlaces006SP />} />

        <Route path="/museum-places-006SP" element={<MuseumPlaces006SP />} />
        <Route path="/concert-places-006SP" element={<ConcertsPlaces006SP />} />
        <Route path="/brand-places-006SP" element={<BrandsPlaces006SP />} />

        // NAV PLACES 007LN

        <Route path="/nightclub-places-007LN" element={<NightClubPlaces007LN />} />
        <Route path="/restaurant-places-007LN" element={<RestaturantPlaces007LN />} />
        <Route path="/bar-places-007LN" element={<BarPlaces007LN />} />

        <Route path="/coffee-places-007LN" element={<CoffeePlaces007LN />} />
        <Route path="/bookstore-places-007LN" element={<BookstorePlaces007LN />} />
        <Route path="/theater-places-007LN" element={<TheaterPlaces007LN />} />

        <Route path="/museum-places-007LN" element={<MuseumPlaces007LN />} />
        <Route path="/concert-places-007LN" element={<ConcertsPlaces007LN />} />
        <Route path="/brand-places-007LN" element={<BrandsPlaces007LN />} />

        // NAV PLACES 008SY

        <Route path="/nightclub-places-008SY" element={<NightClubPlaces008SY />} />
        <Route path="/restaurant-places-008SY" element={<RestaturantPlaces008SY />} />
        <Route path="/bar-places-008SY" element={<BarPlaces008SY />} />

        <Route path="/coffee-places-008SY" element={<CoffeePlaces008SY />} />
        <Route path="/bookstore-places-008SY" element={<BookstorePlaces008SY />} />
        <Route path="/theater-places-008SY" element={<TheaterPlaces008SY />} />

        <Route path="/museum-places-008SY" element={<MuseumPlaces008SY />} />
        <Route path="/concert-places-008SY" element={<ConcertsPlaces008SY />} />
        <Route path="/brand-places-008SY" element={<BrandsPlaces008SY />} />

        // NAV PLACES 009MI

        <Route path="/nightclub-places-009MI" element={<NightClubPlaces009MI />} />
        <Route path="/restaurant-places-009MI" element={<RestaturantPlaces009MI />} />
        <Route path="/bar-places-009MI" element={<BarPlaces009MI />} />

        <Route path="/coffee-places-009MI" element={<CoffeePlaces009MI />} />
        <Route path="/bookstore-places-009MI" element={<BookstorePlaces009MI />} />
        <Route path="/theater-places-009MI" element={<TheaterPlaces009MI />} />

        <Route path="/museum-places-009MI" element={<MuseumPlaces009MI />} />
        <Route path="/concert-places-009MI" element={<ConcertsPlaces009MI />} />
        <Route path="/brand-places-009MI" element={<BrandsPlaces009MI />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
