// NightClub007.js
import React from 'react';
import './Alt.css'; // Import your additional CSS

const NightClub007 = () => {

  const imageUrl ='https://lh5.googleusercontent.com/p/AF1QipPt2zmroHHYbTQdlhas8hux-kl1FOvRVxL50Gt7=w137-h92-k-no';

  return (
    <div className="container">
      <div className="post">
        <div className="title-overlay">릿라운지 이태원</div> {/* Explicitly specify the title here */}
        <img src={imageUrl} alt="릿라운지 이태원"/>
      </div>
    </div>
  );
};

export default NightClub007;
